<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col>
          <h1>Targets</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed color="accent" @click="addTargetForm.dialog=true">
              <v-icon left>mdi-plus</v-icon>Add Target
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :headers="tableHeaders" :items="targets" no-data-text="No targets found">
            <template v-slot:item.year="{item}">{{getYear(item.date)}}</template>
            <template v-slot:item.month="{item}">{{getMonth(item.date)}}</template>
            <template v-slot:item.target="{item}">£{{item.target}}</template>
            <template v-slot:item.actions="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="openEditTarget(item.id)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Target</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="removeTargetForm.dialog=true;removeTargetForm.item=item"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove Target</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="addTargetForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="addTarget" method="post">
          <v-card-title>
            <span class="headline">Add New Target</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-menu ref="dateMenu" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="addTargetForm.date"
                      label="Date *"
                      readonly
                      outlined
                      dense
                      v-on="on"
                      :error="addTargetForm.errors.hasOwnProperty('date')"
                      :error-messages="addTargetForm.errors['date']"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="addTargetForm.date" type="month" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="default" @click="menu=false">Cancel</v-btn>
                    <v-btn text color="success" @click="$ref.menu.save(addTargetForm.date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field
                  label="Target *"
                  type="number"
                  min="0.00"
                  step="100"
                  prefix="£"
                  v-model="addTargetForm.target"
                  outlined
                  dense
                  :error="addTargetForm.errors.hasOwnProperty('target')"
                  :error-messages="addTargetForm.errors['target']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="addTargetForm.dialog=false">Close</v-btn>
            <v-btn color="accent" text type="submit" :loading="addTargetForm.loading">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeTargetForm.dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Remove Target</v-card-title>
        <v-card-text>Are you sure you want to remove this target?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="removeTargetForm.dialog=false">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="removeTargetForm.loading"
            @click="removeTarget()"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editTargetForm.dialog" persistent max-width="600px">
      <v-card>
        <v-form @submit.prevent="editTarget" method="post">
          <v-card-title>
            <span class="headline">Edit Target</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-menu ref="editDateMenu" offset-y min-width="290px">
                  <template v-slot:activator="{on}">
                    <v-text-field
                      v-model="editTargetForm.date"
                      label="Date *"
                      readonly
                      outlined
                      dense
                      v-on="on"
                      :error="editTargetForm.errors.hasOwnProperty('date')"
                      :error-message="editTargetForm.errors['date']"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="editTargetForm.date" type="month" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="default" @click="menu=false">Cancel</v-btn>
                    <v-btn text color="success" @click="$ref.menu.save(editTargetForm.date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field
                  label="Target *"
                  type="number"
                  min="0.00"
                  step="100"
                  prefix="£"
                  v-model="editTargetForm.target"
                  outlined
                  dense
                  :error="editTargetForm.errors.hasOwnProperty('target')"
                  :error-messages="editTargetForm.errors['target']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="editTargetForm.dialog=false">Close</v-btn>
            <v-btn color="accent" text type="submit" :loading="editTargetForm.loading">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as Utils from "@/utils/index.js";

export default {
  data() {
    return {
      tableHeaders: [
        { text: "Year", value: "year" },
        { text: "Month", value: "month" },
        { text: "Target", value: "target" },
        { text: "Actions", value: "actions" }
      ],
      addTargetForm: {
        dialog: false,
        date: null,
        target: null,
        loading: null,
        errors: {}
      },
      removeTargetForm: {
        dialog: false,
        item: null,
        loading: false
      },
      editTargetForm: {
        dialog: false,
        loading: false,
        id: null,
        date: null,
        target: null,
        errors: {}
      }
    };
  },

  computed: {
    targets() {
      return this.$store.state.pipeline["targets"];
    }
  },

  methods: {
    addTarget: function() {
      const appId = this.$route.params.id;
      const date = this.addTargetForm.date;
      const target = this.addTargetForm.target;

      this.addTargetForm.loading = true;

      this.$store
        .dispatch("pipeline/addTarget", {
          appId,
          date,
          target
        })
        .then(() => {
          this.addTargetForm = {
            dialog: false,
            date: null,
            target: null,
            loading: null,
            errors: {}
          };
        })
        .catch(err => {
          this.addTargetForm.errors = err.response.data.errors;
          this.addTargetForm.loading = false;
        });
    },
    removeTarget: function() {
      const appId = this.$route.params.id;
      const id = this.removeTargetForm.item.id;

      this.removeTargetForm.loading = true;

      this.$store
        .dispatch("pipeline/removeTarget", {
          appId,
          id
        })
        .then(() => {
          this.removeTargetForm = {
            dialog: false,
            item: null,
            loading: false
          };
        });
    },
    getYear: function(date) {
      const d = new Date(date);

      return d.getFullYear();
    },
    getMonth: function(date) {
      const d = new Date(date);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      return months[d.getMonth()];
    },
    openEditTarget: function(id) {
      const target = this.targets.find(t => t.id === id);

      this.editTargetForm.dialog = true;
      this.editTargetForm.id = id;
      this.editTargetForm.date = Utils.getDate(target.date, "-", true, true);
      this.editTargetForm.target = target.target;
    },
    editTarget: function() {
      const appId = this.$route.params.id;
      const id = this.editTargetForm.id;
      const date = this.editTargetForm.date;
      const target = this.editTargetForm.target;

      this.editTargetForm.loading = true;

      this.$store
        .dispatch("pipeline/updateTarget", {
          appId,
          id,
          date,
          target
        })
        .then(() => {
          this.editTargetForm = {
            dialog: false,
            loading: false,
            id: null,
            date: null,
            target: null,
            errors: {}
          };
        })
        .catch(err => {
          this.editTargetForm.errors = err.response.data.errors;
          this.editTargetForm.loading = false;
        });
    }
  }
};
</script>